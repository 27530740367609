
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.esmp-modal-body {
  font-size: 16px;
}
.modal-ticket-rate__rate {
  margin-bottom: 24px;
  .esmp-svg-icon {
    width: 60px;
    height: 60px;
  }
}
